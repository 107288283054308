import React from 'react';
import Main from '../components/pages/Main';

const Precos = () => (
  <Main page="precos">
    <div style={{ height: '100vh' }} />
  </Main>
);

export default Precos;
